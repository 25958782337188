import React, { useEffect } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Locations } from "../../components/Locations";
import { LegalClauses } from "../../components/LegalClauses";
import { ProductsRequested } from "../../components/ProductsRequested";
import { labels } from "../../constants";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { useSearchParams } from "react-router-dom";
import { useRfpDetails } from "../../hooks/useRfpDetails";
import {
  convertToLocalDateAndTimeFromGMT,
  differenceInDays,
} from "../../utils";
const Analyze = () => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const rfpName = search.get("rfpName");

  useEffect(() => {
    dispatch(setActiveTab("analyze"));
  }, [dispatch]);

  const { rfpDetails, loading, error } = useRfpDetails(rfpName);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">Error loading RFP details</Alert>;

  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid size={8}>
        <Typography variant="h6">
          {rfpDetails.rfp_info[0].rfp_name || "RFP name"}
        </Typography>
      </Grid>
      <Grid size={4}>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
            alignItems: "center",
            float: "right",
          }}
        >
          <Typography color={grey[800]} variant="body2" fontWeight={500}>
            {labels.rfpDue}{" "}
            {convertToLocalDateAndTimeFromGMT(rfpDetails.rfp_info[0].due_date)}
          </Typography>
          <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
            <AccessTimeFilledIcon />
            <Typography fontWeight={600} variant="body2">
              {differenceInDays(rfpDetails.rfp_info[0].due_date)} days left
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid size={12}>
        <Divider sx={{ borderWidth: 1, borderColor: grey[600] }} />
      </Grid>
      <Grid size={4}>
        <Paper sx={{ bgcolor: grey[50], p: 2, height: "100%" }}>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
            Core Requirements
          </Typography>
          <Typography variant="body2">
            <b>{rfpDetails.rfp_info[0].site_types}</b> Site types
          </Typography>
          <Typography variant="body2">
            <b>{rfpDetails.rfp_info[0].products}</b> Products
          </Typography>
          <Typography variant="body2">
            <b>{rfpDetails.rfp_info[0].locations}</b> Locations
          </Typography>
        </Paper>
      </Grid>
      <Grid size={4}>
        <Paper sx={{ bgcolor: grey[50], p: 2, height: "100%" }}>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
            Secondary Requirements
          </Typography>
          <Typography variant="body2">
            <b>{rfpDetails.rfp_info[0]?.compliance}</b> compliance
          </Typography>
          <Typography variant="body2">
            <b>{rfpDetails.rfp_info[0]?.legal}</b> legal
          </Typography>
          <Typography variant="body2">
            <b>{rfpDetails.rfp_info[0]?.service}</b> service
          </Typography>
        </Paper>
      </Grid>
      <Grid size={4}>
        <Paper sx={{ bgcolor: grey[50], p: 2, height: "100%" }}>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
            <b>{rfpDetails.rfp_info[0].number_of_locations}</b> locations
          </Typography>
          <Box display="flex" alignItems="center" columnGap={1}>
            <FiberManualRecordIcon color="success" fontSize="sm" />
            <Typography
              variant="body2"
              alignItems="center"
              justifyContent="center"
            >
              <b>{rfpDetails.rfp_info[0].validated_locations}</b> validated
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" columnGap={1}>
            <FiberManualRecordIcon color="warning" fontSize="sm" />
            <Typography
              variant="body2"
              alignItems="center"
              justifyContent="center"
            >
              <b>{rfpDetails.rfp_info[0].potential_matches_locations}</b>{" "}
              potential matches
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" columnGap={1}>
            <FiberManualRecordIcon color="error" fontSize="sm" />
            <Typography
              variant="body2"
              alignItems="center"
              justifyContent="center"
            >
              <b>{rfpDetails.rfp_info[0].unknown_locations}</b> unknown
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid size={12}>
        <ProductsRequested products={rfpDetails?.products_requested} />
      </Grid>
      <Grid size={12}>
        <Locations locations={rfpDetails?.locations} />
      </Grid>
      <Grid size={12}>
        <LegalClauses legalClauses={rfpDetails?.legal_clauses} />
      </Grid>
    </Grid>
  );
};

export default Analyze;
