import { useState, useEffect } from "react";
import { getRfpDetails } from "../apis/rfp";

export const useRfpDetails = (rfpName) => {
  const [rfpDetails, setRfpDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRfpDetails = async () => {
      try {
        const data = await getRfpDetails(rfpName);
        setRfpDetails(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRfpDetails();
  }, [rfpName]);

  return { rfpDetails, loading, error };
};
