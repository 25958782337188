import {
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../pages/Login/firebase";

export const getFilesFromStorage = async () => {
  const storage = getStorage();
  const storageRef = ref(storage, "uploads");

  try {
    const response = await listAll(storageRef);
    const files = response.items.map((item) => ({
      name: item.name,
      id: item.fullPath,
    }));
    return files;
  } catch (error) {
    throw error;
  }
};

export const uploadFileToStorage = (file, setProgress) => {
  const storageRef = ref(storage, `uploads/${file.name}`);

  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("progress :>> ", progress);
      setProgress(progress);
    },
    (error) => {
      console.error("Upload failed", error);
    },
    () => {}
  );
};
