import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Subtitle } from "../Subtitle";
import { labels } from "../../constants";
import PropTypes from "prop-types";

export const columns = [
  { field: "product_id", headerName: "ID", width: 150 },
  { field: "product_code", headerName: "Code", width: 150 },
  {
    field: "product_name",
    headerName: "Name",
    minWidth: 250,
  },
  {
    field: "specifications",
    headerName: "Specification",
    flex: 1,
  },
];

const ProductsRequested = ({ products }) => {
  return (
    <Box>
      <Subtitle text={labels.productsRequested} />
      <DataGrid
        getRowId={(row) => row.product_id}
        rows={products}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

ProductsRequested.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductsRequested;
