import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Subtitle } from "../Subtitle";
import { labels } from "../../constants";
import PropTypes from "prop-types";

export const columns = [
  { field: "clause_id", headerName: "ID", width: 150 },
  { field: "legal_clause", headerName: "Clause", flex: 1 },
];

const LegalClauses = ({ legalClauses }) => {
  return (
    <Box>
      <Subtitle text={labels.legalClauses} />
      <DataGrid
        getRowId={(row) => row.clause_id}
        rows={legalClauses}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

LegalClauses.propTypes = {
  legalClauses: PropTypes.array.isRequired,
};

export default LegalClauses;
