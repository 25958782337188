import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginStatus } from "../../redux/slices/login";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import Alert from "@mui/material/Alert";
import { setActiveTab } from "../../redux/slices/tabs";
import { firebaseErrors } from "../../constants";
import { setUser } from "../../redux/slices/user";
import { Box } from "@mui/material";
import { CenteredLayout } from "../../components/CenteredLayout";
import { ButtonLink } from "../../components/ButtonLink";
import { emailAndPasswordSchema } from "../../validationSchemas";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: emailAndPasswordSchema,
    onSubmit: (values) => {
      setError(""); // Clear previous error
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(({ user }) => {
          const userDetails = {
            userId: user?.uid,
            email: user?.email,
            name: user?.displayName,
            photoUrl: user?.photoURL,
            metadata: user?.metadata,
            emailVerified: user?.emailVerified,
          };
          dispatch(setLoginStatus(true));
          dispatch(setUser(userDetails));
          dispatch(setActiveTab("upload"));
          navigate("/upload"); // Redirect on successful login
        })
        .catch((error) => {
          setError(error.code);
          dispatch(setLoginStatus(false));
        });
    },
  });

  return (
    <CenteredLayout>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: 300 }}
        >
          <Typography variant="h6">Sign in to RFP Navigator</Typography>

          {/* Display Error Message */}
          {error && (
            <Alert severity="error" sx={{ width: "100%" }}>
              {firebaseErrors[error]}
            </Alert>
          )}

          <TextField
            sx={{ width: "100%" }}
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            sx={{ width: "100%" }}
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Box sx={{ textAlign: "left", width: "100%" }}>
            <ButtonLink
              text="Forgot your password?"
              onClick={() => {
                navigate("/reset-password");
              }}
            />
          </Box>
          <Button
            sx={{ width: "100%" }}
            color="primary"
            variant="contained"
            type="submit"
          >
            Login
          </Button>

          <ButtonLink
            text="Don't have an account? Create one now."
            onClick={() => {
              navigate("/register");
            }}
          />
        </Stack>
      </form>
    </CenteredLayout>
  );
};

export default Login;
