export const tabs = [
  {
    label: "Upload",
    value: "upload",
  },
  {
    label: "Analyze",
    value: "analyze",
    disabed: true,
  },
  {
    label: "Query",
    value: "query",
  },
];
