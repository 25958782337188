import React from "react";
import Container from "@mui/material/Container";
import { Avatar, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { teal } from "@mui/material/colors";
import { PageTitle } from "../../components/PageTitle";

const Profile = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <Container>
      <PageTitle title="Profile" />
      <Box sx={{ display: "flex", alignItems: "start", columnGap: 2, mt: 4 }}>
        <Avatar
          sx={{ bgcolor: teal[500], width: 80, height: 80 }}
          alt="profile"
        >
          {user?.email?.charAt(0).toUpperCase()}
        </Avatar>

        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          <Typography variant="body2">Email: {user?.email}</Typography>
          <Typography variant="body2">
            Email verified: {user?.emailVerified ? "Yes" : "No"}
          </Typography>
          <Typography variant="body2">
            Created at:{" "}
            {new Date(parseInt(user?.metadata?.createdAt)).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Last login at:{" "}
            {new Date(parseInt(user?.metadata?.lastLoginAt)).toLocaleString()}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
