export const convertToLocalDateAndTimeFromGMT = (gmtDate) => {
  const gmt = new Date(gmtDate);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = gmt.toLocaleDateString(undefined, options);

  return formattedDate;
};

export const differenceInDays = (gmt) => {
  const currentDate = new Date();

  const differenceInTime = new Date(gmt) - currentDate;

  return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
};
