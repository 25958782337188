import httpClient from "../services/httpClient";

export const getRfpDetails = async (rfpName) => {
  try {
    const response = await httpClient.get(`rfp_details/${rfpName}`);
    return response.data;
  } catch (error) {
    throw error; // Throw to handle in hooks or components
  }
};
