import React, { useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import ChatBox from "./Chatbox";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { Subtitle } from "../../components/Subtitle";
import { labels } from "../../constants/labels";

const Query = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveTab("query"));
  }, [dispatch]);
  return (
    <>
      <Subtitle text={labels.queryPageTitle} />
      <Typography color="textPrimary" gutterBottom variant="body2">
        {labels.queryPageDescription}
      </Typography>
      <Paper sx={{ p: 1, mt: 1 }}>
        <ChatBox />
      </Paper>
    </>
  );
};

export default Query;
