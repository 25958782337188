import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  Typography,
  ListItemText,
  LinearProgress,
} from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import axios from "axios";
import { isMobile } from "react-device-detect";

const ChatBox = () => {
  const [listHeight, setListHeight] = useState(0);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const endOfMessagesRef = useRef(null);

  const calculateListHeight = () => {
    const availableHeight = window.innerHeight - (isMobile ? 320 : 280);
    setListHeight(availableHeight);
  };

  useEffect(() => {
    calculateListHeight();
    window.addEventListener("resize", calculateListHeight);
    return () => {
      window.removeEventListener("resize", calculateListHeight);
    };
  }, []);

  const handleSend = async () => {
    if (input.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: input,
          fromSender: true,
          loading: false,
        },
      ]);
      setInput("");

      try {
        setIsLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_QUERY_API}/query`,
          {
            query: input,
          }
        );

        const apiResponse = {
          fromSender: false,
          text: response?.data?.response,
        };
        setMessages((prevHistory) => [...prevHistory, apiResponse]);
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
          fromSender: false,
          text: "Something went wrong. Please try again later.",
        };
        setMessages((prevHistory) => [...prevHistory, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      {isLoading && <LinearProgress color="secondary" />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: listHeight,
        }}
      >
        <Box flexGrow={1} overflow="auto">
          <List>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: message.fromSender
                    ? "flex-end"
                    : "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    backgroundColor: message.fromSender ? teal[50] : grey[100],
                    borderRadius: 1,
                    maxWidth: "75%",
                  }}
                >
                  <ListItemText
                    sx={{
                      textWrap: "wrap",
                    }}
                  >
                    <Typography variant="body2">{message.text}</Typography>
                  </ListItemText>
                </Paper>
              </ListItem>
            ))}
            <div ref={endOfMessagesRef} />
          </List>
        </Box>

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSend();
          }}
        >
          <Box sx={{ display: "flex", columnGap: 1 }}>
            <TextField
              size="small"
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your query"
              sx={{
                "& .MuiInputBase-input": { fontSize: "0.875rem" },
              }}
            />
            <Button
              size="small"
              sx={{ minWidth: 100 }}
              type="submit"
              color="primary"
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChatBox;
