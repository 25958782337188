import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { Upload } from "./pages/Upload";
import { Analyze } from "./pages/Analyze";
import { Query } from "./pages/Query";
import { Register } from "./pages/Register";
import { ResetPassword } from "./pages/ResetPassword";
import { Login } from "./pages/Login";
import { PageNotFound } from "./pages/PageNotFound";
import { Profile } from "./pages/Profile";
import PrivateRoute from "./components/PrivateRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // App acts as a wrapper for routes
    children: [
      {
        path: "upload",
        element: (
          <PrivateRoute>
            <Upload />
          </PrivateRoute>
        ),
      },
      {
        path: "analyze",
        element: (
          <PrivateRoute>
            <Analyze />
          </PrivateRoute>
        ),
      },
      {
        path: "query",
        element: (
          <PrivateRoute>
            <Query />
          </PrivateRoute>
        ),
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);
