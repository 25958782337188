export const byteConverter = (bytes, decimals, type) => {
  const K_UNIT = 1024;
  const SIZES = ["bytes", "KB", "MB", "GB"];

  if (bytes === 0) {
    return "0 byte";
  }

  if (type === "MB") {
    return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  return (
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i]
  );
};

export const fileStatus = (progress) => {
  if (progress) {
    return progress < 100 ? "uploading" : "uploaded";
  } else {
    return "";
  }
};
